import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Datatable from "react-bs-datatable";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";

const BannerList = (props) => {
  const notiRef = useRef();
  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    {
      title: "Banner Image",
      prop: "bannerImage",
      sortable: true,
      filterable: true,
    },
    {
      title: "Banner Type",
      prop: "bannerType",
      sortable: true,
      filterable: true,
    },
    // {
    //   title: "Banner Text",
    //   prop: "bannerFor",
    //   sortable: true,
    //   filterable: true,
    // },
    { title: "Status", prop: "status", sortable: true, filterable: true },
  ];
  const body = [
    {
      id: 1,
      name: "Helen Banks",
      company: "Brainlounge",
      email: "hbanks0@networkadvertising.org",
      phone: "386-(842)278-0044",
      date: moment().subtract(1, "days").format("Do MMMM YYYY"),
    },
    {
      id: 2,
      name: "Russell Wright",
      company: "Trilith",
      email: "rwright1@istockphoto.com",
      phone: "63-(139)594-8042",
      date: moment().subtract(2, "days").format("Do MMMM YYYY"),
    },
  ];
  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "There is no data to be displayed",
    filterPlaceholder: "Search banner",
  };

  const [data, setData] = useState([]);
  const [render, setRerender] = useState(false);

  function action(info) {
    console.log(info);
    api
      .post("/admin/shop/updateBanners", {
        bannerId: info.id,
        status: info.status === "active" ? "inactive" : "active",
      })
      .then((res) => {
        console.log(res.data.data);
        setRerender(!render);
        notify(res.data.data, "success");
      })
      .catch((err) => {
        console.log(err);
        notify(err, "danger");
      });
  }

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };
    notiRef.current.notificationAlert(options);
  }

  useEffect(() => {
    const getCategory = () => {
      api
        .get("admin/shop/")
        .then((resp) => {
          setData(
            resp.data.data.map((info, index) => ({
              ...info,
              id: index + 1,
              bannerImage: (
                <img
                  src={process.env.REACT_APP_BASEDIR + info.bannerImage}
                  width={190}
                  height={100}
                />
              ),
              bannerType: (
                <span style={{ textTransform: "capitalize" }}>
                  {info.bannerType}
                </span>
              ),
              // bannerText: (
              //   <span style={{ textTransform: "capitalize" }}>
              //     {info.bannerText}
              //   </span>
              // ),
              status:
                info.status == "active" ? (
                  <button
                    className="btn btn-danger"
                    style={{ padding: "8px", paddingRight: "10px" }}
                    onClick={() => action(info)}
                  >
                    Delete
                  </button>
                ) : (
                  <button
                    className="btn btn-secondary"
                    style={{ padding: "8px", paddingRight: "10px" }}
                    onClick={() => action(info)}
                  >
                    <i className="i-action-undo"></i> <span>Undo</span>
                  </button>
                ),
              action: (
                <button
                  className="btn btn-secondary btn-icon bottom15 right15"
                  style={{ padding: "8px", paddingRight: "9px" }}
                >
                  <i className="i-pencil"></i> <span>Edit</span>
                </button>
              ),
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        });
      // console.log("resp", resp);
    };
    getCategory();
  }, [props.render, render]);

  return (
    <div className="col-12">
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <section className="box ">
        <header className="panel_header">
          <h2 className="title float-left">Banners List</h2>
        </header>
        <div className="content-body">
          <div className="row">
            <div className="col-lg-12 dt-disp">
              <Datatable
                tableHeader={header}
                tableBody={data}
                keyName="userTable"
                tableClass="striped table-hover table-responsive"
                rowsPerPage={10}
                rowsPerPageOption={[5, 10, 15, 20, 30]}
                initialSort={{ prop: "id", isAscending: true }}
                //   onSort={onSortFunction}
                labels={customLabels}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BannerList;
