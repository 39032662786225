import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Datatable from "react-bs-datatable";
import api from "../Axios";
import NotificationAlert from "react-notification-alert";

const EnqueriesList = (props) => {
  const notiRef = useRef();
  const header = [
    { title: "ID", prop: "id", sortable: true, filterable: true },
    {
      title: "Company Name",
      prop: "companyName",
      sortable: true,
      filterable: true,
    },
    {
      title: "Company Email",
      prop: "companyEmail",
      sortable: true,
      filterable: true,
    },
    {
      title: "Company Contact",
      prop: "companyContact",
      sortable: true,
      filterable: true,
    },
    {
      title: "Message",
      prop: "message",
      sortable: true,
      filterable: true,
    },
    // {
    //   title: "Bulk Order",
    //   prop: "bulkOrder",
    //   sortable: true,
    //   filterable: true,
    // },
  ];

  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display ",
    entries: " rows",
    noResults: "There is no data to be displayed",
    filterPlaceholder: "Search enquiry",
  };
  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };
    notiRef.current.notificationAlert(options);
  }
  const [data, setData] = useState([]);
  const [render, setRerender] = useState(false);
  useEffect(() => {
    const getCategory = () => {
      api
        .get("/admin/shop/bulkOrders")
        .then((resp) => {
          setData(
            resp.data.data.map((info, i) => ({
              id: <span style={{ textTransform: "capitalize" }}>{++i}</span>,
              companyName: (
                <span style={{ textTransform: "capitalize" }}>
                  {info.companyName}
                </span>
              ),
              companyEmail: (
                <span style={{ textTransform: "capitalize" }}>
                  {info.companyEmail}
                </span>
              ),
              message: (
                <span style={{ textTransform: "capitalize" }}>
                  {info.message}
                </span>
              ),
              companyContact: (
                <span style={{ textTransform: "capitalize" }}>
                  {info.companyContact}
                </span>
              ),
              // bulkOrder: (
              //     <span style={{ textTransform: "capitalize" }}>
              //       {info.bulkOrder}
              //     </span>
              //   ),
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        });
      // console.log("resp", resp);
    };
    getCategory();
  }, [props.render, render]);
  return (
    <div className="col-12">
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <section className="box " style={{ marginTop: "6rem" }}>
        <header className="panel_header">
          <h2 className="title float-left">Enqueries List</h2>
        </header>
        <div className="content-body">
          <div className="row">
            <div className="col-lg-12 dt-disp">
              <Datatable
                tableHeader={header}
                tableBody={data}
                keyName="userTable"
                tableClass="striped table-hover table-responsive"
                rowsPerPage={10}
                rowsPerPageOption={[5, 10, 15, 20, 30]}
                initialSort={{ prop: "id", isAscending: true }}
                //   onSort={onSortFunction}
                labels={customLabels}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EnqueriesList;
