import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import Cropper from "../Cropper/CropperFC";
import { useParams } from "react-router-dom";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import FieldRequirementMarker from "../../components/FieldRequirementMarker";

const UpdateCategory = () => {
  const param = useParams();
  const notiRef = useRef();
  // console.log(param);
  const [data, setData] = useState({
    categoryName: "",
    categoryImage: "",
    taxPercentage: "",
    categoryId: null,
  });
  const [GetImage, SetGetImage] = useState("");
  const [reset, setReset] = useState(false);

  // /image cropper/ category Image
  const [selectedImage, setSelectedImage] = useState("");
  const [croppedView, setCroppedView] = useState("");

  const categoryId = param.id;

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
    var redirect =
      type === "success"
        ? setTimeout(() => (window.location.href = "/category"), 1500)
        : "";
  }

  function getApiData() {
    api
      .get("/admin/product/getCategory", {
        params: { categoryId: categoryId || null },
      })
      .then((res) => {
        console.log("Datas", res.data.data);
        setData(res.data.data);
        SetGetImage(res.data.data.categoryImage);
      })
      .catch((err) => {
        console.log(err.response);
        notify(err.response.data.error.message, "danger");
      });
  }

  console.log(data);
  useEffect(() => {
    getApiData();
  }, []);

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const final = {
      categoryName: data.categoryName,
      image: data.categoryImage,
      taxPercentage: data.taxPercentage,
      categoryId,
    };

    const formdata = new FormData();
    Object.entries(final).map((data) => {
      formdata.append(data[0], data[1]);
    });

    api.post("/admin/product/updateCategory", formdata).then((res) => {
      console.log(res);
      notify(res.data.data, "success");
      setReset(!reset);
      setData("");
    });
  }

  const categoryImageURL =
    croppedView || process.env.REACT_APP_BASEDIR + GetImage;

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Category</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Update Category</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label htmlFor="exampleEmail7">
                            Category Name <FieldRequirementMarker type="*" />
                          </Label>
                          <Input
                            type="text"
                            name="categoryName"
                            id="exampleEmail7"
                            onChange={handleChange}
                            value={data.categoryName}
                            className="update-cate"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="categoryImage">
                            Category Image
                            <FieldRequirementMarker type="*" />{" "}
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Dimension: 480px X 480px | Max size: 3MB)
                            </span>
                          </Label>

                          <br />
                          <input
                            className="mt-3"
                            id="categoryImage"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              if (file) setSelectedImage(file);
                            }}
                          />
                          <br />
                          {/* Cropper */}
                          <div
                            className="row d-flex my-3"
                            style={{ rowGap: "15px" }}
                          >
                            <div className="col-12 col-lg-6">
                              <img
                                src={categoryImageURL}
                                width={200}
                                alt="img"
                              />
                            </div>
                            {/* Cropper Tool */}
                            <div
                              className="col-12 col-lg-6"
                              style={{ lineHeight: "0" }}
                            >
                              <Cropper
                                srcImageFile={selectedImage}
                                aspectRatio={"480/480"}
                                getCroppedFile={(croppedFile) => {
                                  setData({
                                    ...data,
                                    categoryImage: croppedFile,
                                  });
                                }}
                                getCroppedUrl={(CroppedUrl) => {
                                  setCroppedView(CroppedUrl);
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleEmail7">
                            Tax Percentage <FieldRequirementMarker type="*" />
                          </Label>
                          <Input
                            type="number"
                            name="taxPercentage"
                            id="exampleEmail7"
                            onChange={handleChange}
                            value={data.taxPercentage}
                          />
                        </FormGroup>

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ backgroundColor: "#000", color: "#fff" }}
                          >
                            Update
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateCategory;
