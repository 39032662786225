import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const Cropper = ({
  srcImageFile = null,
  getCroppedFile = (croppedFile) => {},
  getCroppedUrl = (CroppedUrl) => {},
  aspectRatio = "1/1",
}) => {
  // string to number, aspectRatio
  const [aspectRatioWidth, aspectRatioHeight] = aspectRatio
    .split("/")
    .map(Number);

  const cropperImageRef = useRef(null);
  const [cropperPosition, setCropperPosition] = useState({
    // here values are measured in %
    unit: "px",
    aspect: aspectRatioWidth / aspectRatioHeight,
    // height doesn't needed, cause we use aspect-ratio
    width: 100,
    // x,y are initial cropper positions
    x: 50,
    y: 50,
  });
  const [originalFileDataUrl, setOriginalFileDataUrl] = useState(null);

  useEffect(() => {
    if (srcImageFile) {
      // set to originalFileDataUrl
      createOriginalFileDataUrl(srcImageFile);
    } else {
      // reset to default
      setOriginalFileDataUrl(null);
      getCroppedUrl(null);
    }
  }, [srcImageFile]);

  // 1st step: convert image file to base64 string
  const createOriginalFileDataUrl = (file) => {
    const reader = new FileReader();

    reader.addEventListener("load", () =>
      setOriginalFileDataUrl(reader.result)
    );

    if (file) reader.readAsDataURL(file);
  };

  // 2.1 step: createCroppedBlob
  const createCroppedBlob = async (cropperImageRef, cropperPosition) => {
    // creating canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    /* setting canvas width & height allows us to 
    resize from the original image resolution */
    canvas.width = aspectRatioWidth;
    canvas.height = aspectRatioHeight;

    const scaleX = cropperImageRef.naturalWidth / cropperImageRef.width;
    const scaleY = cropperImageRef.naturalHeight / cropperImageRef.height;

    // creating Cropped version of Canvas
    ctx.drawImage(
      // source Image ref
      cropperImageRef,
      // passing cropper positions
      cropperPosition.x * scaleX,
      cropperPosition.y * scaleY,
      cropperPosition.width * scaleX,
      cropperPosition.height * scaleY,
      0,
      0,
      // output width and height
      canvas.width,
      canvas.height
    );

    // create and return blob from Canvas
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  };

  // 2nd step
  const onCropComplete = async (pxCropperPosition) => {
    //1. create croppedBlob based on CropperPosition
    const croppedBlob = await createCroppedBlob(
      cropperImageRef.current,
      pxCropperPosition
    );

    //2. croppedBlob to objectURL to display it,
    const CroppedDataUrl = window.URL.createObjectURL(croppedBlob);
    getCroppedUrl(CroppedDataUrl);

    //3. convert croppedBlob into File and send to getCroppedFile()
    var croppedFile = new File([croppedBlob], "IMG" + Date.now(), {
      type: "image/png",
    });
    getCroppedFile(croppedFile);
  };

  if (!srcImageFile) return null;

  return (
    originalFileDataUrl && (
      <ReactCrop
        // to display the file => we need data URL
        src={originalFileDataUrl}
        crop={cropperPosition}
        // to show the grids
        ruleOfThirds
        // set cropperImageRef -> recreate cropped version form it
        onImageLoaded={(CropperHTMLImageElement) => {
          cropperImageRef.current = CropperHTMLImageElement;
        }}
        // update cropper position and retain
        onChange={(pxCropperPosition) => {
          // to not allow empty crop or very small crop
          if (!(pxCropperPosition.width < 100)) {
            setCropperPosition(pxCropperPosition);
          }
        }}
        /* on stop cropping, get the final CropperPosition 
    to calc new cropped version of image */
        onComplete={(pxCropperPosition) => {
          onCropComplete(pxCropperPosition);
        }}
      />
    )
  );
};

export default React.memo(Cropper);
