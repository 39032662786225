import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../Axios";
import Cropper from "../Cropper/CropperFC";

const VariantImageUploader = ({
  aspectRatio = "480/480",
  getUploadedImageUrl = (url) => {},
  onUpdateExistingImageUrl = null,
}) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [croppedView, setCroppedView] = useState("");

  const [croppedFile, setCroppedViewFile] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (onUpdateExistingImageUrl) {
      setCroppedView(onUpdateExistingImageUrl);
    }
  }, []);

  const uploadImage = () => {
    if (!croppedFile) return;

    var uploadData = new FormData();
    uploadData.append("variantImage", croppedFile);

    setLoading(true);
    Swal.fire({
      icon: "info",
      title: "Image Uploading...",
      text: "Please wait",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    axios
      .post("/admin/product/uploadImage", uploadData)
      .then((res) => {
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Image Uploaded successfully!",
            showConfirmButton: false,
            timer: 1000,
          });
          //
        }, 2000);

        setLoading(false);
        setCroppedViewFile(null);
        setSelectedImage("");

        const uploadedUrl = res.data.data || "";
        getUploadedImageUrl(uploadedUrl);
      })
      .catch((err) => {
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Image Upload Failed!",
            showConfirmButton: false,
            timer: 1000,
          });
        }, 2000);

        setLoading(false);
        setCroppedViewFile(null);
        setSelectedImage("");
      });
  };

  return (
    <>
      <input
        required={!onUpdateExistingImageUrl}
        type="file"
        accept="image/*"
        onChange={(event) => {
          const file = event.target.files[0];
          if (file) setSelectedImage(file);
        }}
      />

      <br />
      {/* Cropper */}
      {(selectedImage || croppedView) && (
        <div className="row d-flex my-3" style={{ rowGap: "10px" }}>
          {/* Cropper Output */}
          {croppedView && (
            <div className="col-12 col-lg-6 d-flex align-items-center">
              <img
                src={croppedView}
                style={{
                  display: "block",
                  width: "100%",
                }}
              />
            </div>
          )}

          {/* Cropper Tool */}
          {selectedImage && (
            <>
              <div className="col-12 col-lg-6" style={{ lineHeight: "0" }}>
                <Cropper
                  srcImageFile={selectedImage}
                  aspectRatio={aspectRatio}
                  getCroppedFile={(croppedFile) =>
                    setCroppedViewFile(croppedFile)
                  }
                  getCroppedUrl={(CroppedUrl) => {
                    setCroppedView(CroppedUrl);
                  }}
                />
              </div>

              {/* submit btn */}
              <div className="col-12">
                <button
                  disabled={isLoading}
                  onClick={uploadImage}
                  className="btn btn-primary px-3 py-2"
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  {isLoading ? "Uploading..." : "Upload"}
                </button>{" "}
                <span style={{ color: "red", fontWeight: "bold" }}>
                  (Upload the Image before submit)
                </span>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(VariantImageUploader);
