import "rc-color-picker/assets/index.css";
import React, { useEffect, useRef, useState } from "react";
import TagsInput from "react-tagsinput";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import api from "../../Axios";
import { customStyles } from "../../assets/css/CustomStyles";
import FieldRequirementMarker from "../../components/FieldRequirementMarker";
import VariantImageUploader from "./VariantImageUploader";

const AddVariant = () => {
  const notiRef = useRef();

  const [data, setData] = useState({
    productId: "",
    productName: "",
    tax: "",
    variantName: "",
    variantImage: [],
    tags: [],
    variantColor: [],
    actualPrice: [],
    discountPrice: [],
  });

  const [variantcount, setVariantCount] = useState(1);
  const [productList, setProductList] = useState([]);
  const [render, setRender] = useState(false);

  const [dummy, setDummy] = useState(true);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: type == "danger" ? 5 : 3,
    };
    notiRef.current.notificationAlert(options);
  }

  //----------Getting product details --------
  useEffect(() => {
    api
      .get("/admin/product/getProduct/" + null)
      .then((res) => {
        if (Array.isArray(res.data.data))
          setProductList(
            res.data.data.map((data) => {
              return {
                label: data.productName,
                productId: data.id,
                tax: data.tax,
              };
            })
          );
      })
      .catch((error) => console.log("error", error));
  }, []);
  // inputHandlers
  function handleChange(e, i, fieldName) {
    console.log(data, i);
    // product default details handle
    if (fieldName === "productName")
      setData({
        ...data,
        productId: e.productId,
        productName: e.label,
        tax: e.tax,
      });

    //variant Name
    if (fieldName === "variantName")
      setData({ ...data, variantName: e.target.value });

    // tags handle
    if (fieldName === "tags") {
      setData({ ...data, tags: e });
    }

    //image handle
    if (fieldName === "image") data.variantImage[i] = e;

    // //other all
    if (fieldName === undefined) data[e.target.name][i] = e.target.value;

    //setting value in reference level, it wont rerender,
    setDummy(!dummy);
  }

  // submit handler
  function handSubmit(e) {
    e.preventDefault();

    if (!data.tags.length) return;
    if (!data.variantImage.length) return;

    e.currentTarget.reset();

    api
      .post("/admin/product/addVariant", data)
      .then((res) => {
        console.log(res.data.data);
        notify(res.data.data, "success");
        // just reset
        setData({
          productId: "",
          productName: "",
          tax: "",
          variantName: [],
          variantImage: [],
          tags: [[]],
          variantColor: [],
          actualPrice: [],
          discountPrice: [],
        });
        // setVariantCount(1);
        setRender(!render);
      })
      .catch((err) => {
        console.log(err);
        notify(err, "danger");
      });
  }

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Product Variants</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Add Product Variant</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handSubmit}>
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">
                            Product Name <FieldRequirementMarker type="*" />
                          </Label>
                          <Select
                            required
                            className="select2"
                            options={productList}
                            styles={customStyles}
                            onChange={(e) => {
                              handleChange(e, undefined, "productName");
                            }}
                          />
                        </FormGroup>

                        {/* variant name */}
                        <FormGroup>
                          <Label htmlFor="exampleEmail7">
                            Variant Name <FieldRequirementMarker type="*" />
                          </Label>
                          <div className="row">
                            <div className="col-lg-6">
                              {" "}
                              <Input
                                type="text"
                                name="variantName"
                                id="exampleEmail7"
                                placeholder=""
                                value={data.variantName}
                                onChange={(e) => {
                                  handleChange(e, undefined, "variantName");
                                }}
                                required
                              />
                            </div>
                          </div>
                        </FormGroup>

                        {/* tags */}
                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Tags <FieldRequirementMarker type="*" />
                            <span
                              style={{
                                color: "#f9c113",
                                fontWeight: "bold",
                              }}
                            >
                              (Keywords used for searching products in website)
                            </span>
                          </Label>
                          <div className="form-group">
                            <div className="controls">
                              <TagsInput
                                value={data.tags}
                                name="tags"
                                onChange={(e) => {
                                  handleChange(e, undefined, "tags");
                                }}
                                onlyUnique={true}
                                inputProps={{
                                  placeholder: "Type and press Enter",
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        {/* <FormGroup>
                          <Label htmlFor="exampleEmail7">Variant Name</Label>
                          <div className="row">
                            <div className="col-lg-6">
                              {" "}
                              <Input
                                type="text"
                                name="variantName"
                                id="exampleEmail7"
                                placeholder=""
                                onChange={handleChange}
                                required
                              />
                            </div>
                           
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Tags{" "}
                            <span
                              style={{ color: "#ffb74d", fontWeight: "bold" }}
                            >
                              (Keywords used for searching products in website)
                            </span>
                          </Label>
                          <div className="form-group">
                            <div className="controls">
                              <TagsInput
                                value={data.tags}
                                name="tags"
                                onChange={setTags}
                                onlyUnique={true}
                              />
                            </div>
                          </div>
                        </FormGroup> */}

                        {Array.from({ length: variantcount }, (x, i) => (
                          <div key={i}>
                            {i > 0 ? (
                              <div>
                                <br />
                                <hr style={{ borderColor: "#526db4" }} />
                                <br />
                              </div>
                            ) : (
                              ""
                            )}

                            {/* image */}
                            <FormGroup>
                              <Label>
                                Variant Image
                                <FieldRequirementMarker type="*" />{" "}
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  (Dimension: 480px X 480px | Max size: 3MB)
                                </span>
                              </Label>

                              <br />

                              <VariantImageUploader
                                aspectRatio="480/480"
                                getUploadedImageUrl={(url) => {
                                  handleChange(url, i, "image");
                                }}
                              />
                            </FormGroup>

                            {/* color picker */}
                            <FormGroup>
                              <Label htmlFor="exampleText">
                                Color <FieldRequirementMarker type="*" />
                              </Label>
                              <div
                                className="form-group"
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                {/* <ColorPicker
                                  defaultColor="#000"
                                  animation="slide"
                                  onChange={(e) => handleArrayChange(e, i)}
                                /> */}

                                <input
                                  required
                                  name="variantColor"
                                  type="color"
                                  value={data.variantColor[i]}
                                  onChange={(e) => handleChange(e, i)}
                                />
                              </div>
                            </FormGroup>

                            {/* actual price */}
                            <FormGroup>
                              <Label htmlFor="exampleText">
                                Actual Price <FieldRequirementMarker type="*" />
                              </Label>
                              <Input
                                type="number"
                                name="actualPrice"
                                id="exampleEmail7"
                                placeholder=""
                                onChange={(e) => handleChange(e, i)}
                                required
                              />
                            </FormGroup>

                            {/* discount price */}
                            <FormGroup>
                              <Label htmlFor="exampleText">
                                Discount Price
                                <FieldRequirementMarker type="*" />
                              </Label>
                              <Input
                                type="number"
                                name="discountPrice"
                                id="exampleEmail7"
                                placeholder=""
                                onChange={(e) => handleChange(e, i)}
                                required
                              />
                            </FormGroup>
                            {/* </div> */}
                          </div>
                        ))}

                        {/* add variant button */}
                        {/* <FormGroup>
                          <div
                            className="btn btn-primary"
                            onClick={() => {
                              data.tags.push([]);
                              setVariantCount(variantcount + 1);
                            }}
                          >
                            +
                          </div>
                          {variantcount > 1 ? (
                            <div
                              className="btn btn-primary"
                              onClick={() => {
                                data.tags.pop();
                                setVariantCount(variantcount - 1);
                              }}
                            >
                              -
                            </div>
                          ) : (
                            ""
                          )}
                        </FormGroup> */}

                        {/* <FormGroup>
                          <Label htmlFor="exampleEmail7">Available Stock</Label>
                          <Input
                            type="text"
                            name="availableStock"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                            required
                          />
                        </FormGroup> */}

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#000",
                              color: "#fff",
                            }}
                          >
                            Submit
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* <ProductList /> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddVariant;
