import React, { useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import api from "../../Axios";
import FieldRequirementMarker from "../../components/FieldRequirementMarker";
import BannerList from "./BannerList";
import Cropper from "../Cropper/CropperFC";

const AddBanner = () => {
  const notiRef = useRef();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    image: "",
  });
  const bannertype = [
    { value: "offer", label: "offer", id: "bannerType" },
    { value: "normal", label: "normal", id: "bannerType" },
    { value: "festival", label: "festival", id: "bannerType" },
  ];

  const [render, setRender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
  }

  const [selectedBanner, setSelectedBanner] = useState("");
  const [croppedView, setCroppedView] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (!data.image) return;

    setLoad(true);
    const formdata = new FormData();

    Object.entries(data).map(([key, value]) => {
      formdata.append(key, value);
    });

    // Reset the form fields
    e.target.reset();

    api
      .post("/admin/shop/addBanners", formdata)
      .then((res) => {
        setLoad(false);
        setData("");

        notify(res.data.data, "success");
        setRender(!render);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      })
      .finally(() => {
        setSelectedBanner("");
      });
  }

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>

        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Banners</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Add Banner</h2>
                </header>
                <div className="content-body">
                  <div className="row ">
                    <div className="col-12">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label htmlFor="bannerImage">
                            Banner Image
                            <FieldRequirementMarker type="*" />{" "}
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Dimension: 1500px X 653px | Max size: 10MB)
                            </span>
                          </Label>

                          <br />
                          <input
                            required
                            id="bannerImage"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              if (file) setSelectedBanner(file);
                            }}
                          />
                          <br />
                          {/* Cropper */}
                          <div
                            className="row d-flex my-3"
                            style={{ rowGap: "15px" }}
                          >
                            {/* Cropper Tool */}
                            <div
                              className="col-12 col-lg-6"
                              style={{ lineHeight: "0" }}
                            >
                              <Cropper
                                srcImageFile={selectedBanner}
                                aspectRatio={"1500/653"}
                                getCroppedFile={(croppedFile) => {
                                  setData({ ...data, image: croppedFile });
                                }}
                                getCroppedUrl={(CroppedUrl) => {
                                  setCroppedView(CroppedUrl);
                                }}
                              />
                            </div>
                            {/* Cropper Output */}
                            <div className="col-12 col-lg-6 d-flex align-items-center">
                              {croppedView && (
                                <img
                                  src={croppedView}
                                  style={{
                                    display: "block",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </FormGroup>

                        {/* <FormGroup>
                          <Label htmlFor="bannerType">Banner Type</Label>
                          <Select
                            name="bannerType"
                            className="select2"
                            options={bannertype}
                            styles={customStyles}
                            onChange={handleChange}
                          />
                        </FormGroup> */}
                        {/* <FormGroup>
                      <Label htmlFor="exampleEmail7">Banner Text</Label>
                      <Input
                        type="text"
                        name="bannerText"
                        id="exampleEmail7"
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </FormGroup> */}
                        {/* <FormGroup>
                      <Label htmlFor="exampleEmail7">Link</Label>
                      <Input
                        type="text"
                        name="bannerLink"
                        id="exampleEmail7"
                        onChange={handleChange}
                        placeholder=""
                        required
                      />
                    </FormGroup> */}

                        {/* <FormGroup>
                          <Label htmlFor="bannerFor">Banner For</Label>
                          <FormGroup tag="fieldset">
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="bannerFor"
                                  value="product"
                                  onChange={handleChange}
                                />{" "}
                                Product
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="bannerFor"
                                  value="category"
                                  onChange={handleChange}
                                />{" "}
                                Category
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </FormGroup> */}

                        {/* {data.bannerFor ? (
                          <FormGroup>
                            <Label htmlFor="exampleSelect3">Choose Type</Label>
                            <Select
                              name="bannerType"
                              className="select2"
                              options={
                                data.bannerFor == "product"
                                  ? productList
                                  : categoryList
                              }
                              styles={customStyles}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        ) : (
                          " "
                        )} */}

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            disabled={load}
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              color: "white",
                              backgroundColor: "#000",
                            }}
                          >
                            {load ? "Loading..." : "Submit"}
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <BannerList render={render} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddBanner;
