import React from "react";
import styled from "styled-components";

const RequirementMarker = styled.span`
  line-height: 1;
  font-size: ${(prop) => (prop.type === "!" ? "12px" : "20px")};
  color: ${(prop) => prop.color};
`;

const FieldRequirementMarker = ({ type }) => {
  const fieldTypes = [
    {
      color: "grey",
      type: "!",
      label: "(Optional)",
    },
    {
      color: "red",
      type: "*",
      label: "*",
    },
  ];

  const currentField = fieldTypes.find((field) => type === field.type);

  return (
    <RequirementMarker type={currentField.type} color={currentField.color}>
      {currentField.label}
    </RequirementMarker>
  );
};

export default FieldRequirementMarker;
