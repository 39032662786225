import {} from "components";
import React, { useRef, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import NotificationAlert from "react-notification-alert";
import api from "../Axios";
import FieldRequirementMarker from "../components/FieldRequirementMarker";
import Cropper from "./Cropper/CropperFC";

const Welcome = () => {
  const notiRef = useRef();
  const [data, setData] = useState({
    image: "",
    message: "",
    // content: "",
  });

  const [render, setRender] = useState(false);

  const [selectedPopupImage, setSelectedPopupImage] = useState("");
  const [croppedView, setCroppedView] = useState("");

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
  }

  function handleChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleImageChange(e) {
    setData({ ...data, image: e });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const formdata = new FormData();
    Object.entries(data).map((data) => {
      formdata.append(data[0], data[1]);
    });

    api
      .post("/admin/shop/home", formdata)
      .then((res) => {
        setData({
          image: "",
          message: "",
        });

        notify(res.data.data, "success");
        setRender(!render);
      })
      .catch((err) => {
        console.log(err);
      });
    e.currentTarget.reset();
  }
  // console.log("CategoryList", data.bannerFor);

  return (
    <div className="content">
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <Row>
        <Col xs={12} md={12}>
          <div className="page-title">
            <div className="float-left">
              <h1 className="title"> Welcome Attributes</h1>
            </div>
          </div>

          <div className="col-12">
            <section className="box ">
              <header className="panel_header">
                <h2 className="title float-left">Add Welcome Attributes</h2>
              </header>
              <div className="content-body">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label htmlFor="PopUpImage">
                          Pop Up Image
                          <FieldRequirementMarker type="*" />{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            (Dimension: 1280px X 720px | Max size: 10MB)
                          </span>
                        </Label>

                        <br />
                        <input
                          required
                          id="PopUpImage"
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) setSelectedPopupImage(file);
                          }}
                        />
                        <br />
                        {/* Cropper */}
                        <div
                          className="row d-flex my-3"
                          style={{ rowGap: "15px" }}
                        >
                          {/* Cropper Tool */}
                          <div
                            className="col-12 col-lg-6"
                            style={{ lineHeight: "0" }}
                          >
                            <Cropper
                              srcImageFile={selectedPopupImage}
                              aspectRatio={"1280/720"}
                              getCroppedFile={(croppedFile) => {
                                setData({ ...data, image: croppedFile });
                              }}
                              getCroppedUrl={(CroppedUrl) => {
                                setCroppedView(CroppedUrl);
                              }}
                            />
                          </div>
                          {/* Cropper Output */}
                          <div className="col-12 col-lg-6 d-flex align-items-center">
                            {croppedView && (
                              <img
                                src={croppedView}
                                style={{
                                  display: "block",
                                  width: "100%",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="exampleEmail7">
                          Welcome Message <FieldRequirementMarker type="*" />
                        </Label>
                        <Input
                          type="text"
                          name="message"
                          id="exampleEmail7"
                          onChange={handleChange}
                          placeholder="Enter Your Message"
                          required
                        />
                      </FormGroup>

                      <FormGroup style={{ marginBottom: "0px" }}>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{
                            color: "white",
                            backgroundColor: "#000",
                          }}
                        >
                          Submit
                        </button>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Welcome;
