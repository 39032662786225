import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import {} from "components";
import { useParams } from "react-router-dom";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import ColorPicker from "rc-color-picker";
import FieldRequirementMarker from "../../components/FieldRequirementMarker";

const UpdateStock = () => {
  const param = useParams();
  const variantId = param.id;
  const [variantcount, setVariantCount] = useState(0);
  const notiRef = useRef();
  const [data, setData] = useState({
    alternateStock: [],
    availableStock: [],
    variantImage: [],
    variantColor: [],
  });
  const parse = (data) => {
    try {
      return Array.isArray(JSON.parse(data)) ? JSON.parse(data) : [];
    } catch {
      return [];
    }
  };

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
    var redirect =
      type === "success"
        ? setTimeout(() => (window.location.href = "/stock"), 1300)
        : "";
  }

  useEffect(() => {
    api
      .post("/admin/product/", { variantId: variantId })
      .then((res) => {
        console.log("test", res.data.data[0].variantImage);
        setData(
          res.data.data.map((info) => ({
            ...info,
            availableStock: parse(info.availableStock),
            alternateStock: parse(info.alternateStock),
            variantImage: parse(info.variantImage),
            variantColor: parse(info.variantColor),
          }))[0]
        );
        //   console.log("image", JSON.parse(res.data.data[0].variantImage));
      })
      .catch((err) => {
        console.log(err);
        notify(err, "danger");
      });
  }, []);

  function handleChange(e, i) {
    console.log(e);
    data[e.target.name] = Number(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();

    setData({ ...data, variantId: variantId });
    const final = {
      alternateStock: 0,
      availableStock: data.availableStock,
      variantId: variantId,
    };
    console.log("submitted", final);
    api
      .post("/admin/product/updateStock", final)
      .then((res) => {
        console.log(res);
        notify(res.data.data, "success");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Stock Management</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Update Stock</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        {Array.from(
                          { length: data.variantImage.length },
                          (e, i) => (
                            <>
                              <FormGroup>
                                <img
                                  src={
                                    process.env.REACT_APP_BASEDIR +
                                    data.variantImage[i]
                                  }
                                  width={130}
                                  height={130}
                                  alt="img"
                                />
                              </FormGroup>

                              <FormGroup>
                                <ColorPicker
                                  animation="slide"
                                  color={data.variantColor[i]}
                                  className="color-css"
                                />
                              </FormGroup>

                              <FormGroup>
                                <Label htmlFor="exampleEmail7">
                                  Existing Stock :{" "}
                                  {data.availableStock.length === 0
                                    ? 0
                                    : data.availableStock[i]}
                                </Label>
                              </FormGroup>

                              <FormGroup>
                                <Label htmlFor="exampleEmail7">
                                  New Stock <FieldRequirementMarker type="*" />
                                </Label>
                                <Input
                                  type="number"
                                  name="availableStock"
                                  onChange={(e) => handleChange(e, i)}
                                  // value={data.categoryName}
                                  className="update-cate"
                                />
                              </FormGroup>

                              {/* <FormGroup>
                                <Label htmlFor="exampleEmail7">
                                  Alternate Stock
                                </Label>
                                <Input
                                  type="number"
                                  name="alternateStock"
                                  onChange={(e) => handleChange(e, i)}
                                  // value={data.taxPercentage}
                                />
                              </FormGroup> */}
                            </>
                          )
                        )}
                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ color: "#fff" }}
                          >
                            Update
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateStock;
