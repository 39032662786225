import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import ProductList from "./ProductList";
import Cropper from "../Cropper/CropperFC";
import TagsInput from "react-tagsinput";
import Select from "react-select";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import { customStyles } from "../../assets/css/CustomStyles";
import FieldRequirementMarker from "../../components/FieldRequirementMarker";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddProduct = (props) => {
  // quill helpers start
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      ["clean"],
    ],
  };
  // quill helpers end

  const notiRef = useRef();
  const [taxlist, setTaxlist] = useState();

  const [data, setData] = useState({
    categoryId: "",
    categoryName: "",
    tax: "",
    image: "",
    // age:"",
    productName: "",
    productDescription: "",
    // productFormula:"",
    // customize:"",
    moreInfo: "",
    productSpecification: [],
    tags: [],
  });

  // /image cropper/ Product Image
  const [selectedImage, setSelectedImage] = useState("");
  const [croppedView, setCroppedView] = useState("");

  // const productFormula = [
  //   { value: "1", label: "Flat & Fitted Bedsheets", id: "productFormula" },
  //   { value: "2", label: "Mattress Protector", id: "productFormula" },
  //   { value: "3", label: "Pillow Protector", id: "productFormula" },
  //   { value: "4", label: "Mattress Comforter", id: "productFormula" },
  //   { value: "5", label: "Dohar", id: "productFormula" },
  //   { value: "6", label: "Fitted Bedsheets", id: "productFormula" },
  //   { value: "7", label: "Flat Bedsheets", id: "productFormula" },

  // ];

  function handleChanges(e) {
    if (e.label) {
      setData({ ...data, productFormula: e.value });
      // e.id == "prodcate"
      //   ? setData({ ...data, productOrCategoryId: e.finalId })
      //   : setData({ ...data, bannerType: e.value });
    }
    // setData({...data, bannerType:e.value })
    else setData({ ...data, [e.target.name]: e.target.value });
  }
  const [catelist, setCateList] = useState();
  const [valueList, setValueList] = useState([]);
  const [render, setRender] = useState(false);
  const [submitRender, setSubmitRender] = useState(false);

  function notify(msg, type) {
    var type = type;
    var options = {};
    options = {
      place: "tc",
      message: (
        <div className="notification-msg">
          <div className="text-center">{msg}</div>
        </div>
      ),
      type: type,
      icon: "",
      autoDismiss: 3,
    };

    notiRef.current.notificationAlert(options);
  }
  // Get Tax
  useEffect(() => {
    const getTax = () => {
      api
        .post("/admin/shop/tax")
        .then((res) => {
          if (res.data.data != "No Taxes found") {
            setTaxlist(
              res.data.data.map((data) => {
                return {
                  label: data.taxName,
                  value: data.id,
                  taxPercentage: data.taxPercentage,
                  taxId: data.id,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTax();
  }, [props.taxRender]);

  function handleChanged(e) {
    if (e.label) {
      setData({
        ...data,
        taxId: e.taxId,
      });
    } else setData({ ...data, [e.target.name]: e.target.value });
  }
  //-------------- GET CATEGORY ------------------
  useEffect(() => {
    api.get("/admin/product/getCategory").then((res) => {
      if (Array.isArray(res.data.data))
        setCateList(
          res.data.data.map((data) => {
            return {
              label: data.categoryName,
              value: data.id,
              id: data.id,
              tax: data.taxPercentage,
            };
          })
        );
    });
  }, []);
  //------------- SPEC --------------------
  useEffect(() => {
    if (render) {
      setRender(false);
      api
        .post("/admin/product/title", { categoryId: data.categoryId })
        .then((res) => {
          if (res.data.data == "No Details Found") return setValueList([]);
          else {
            setValueList(
              res.data.data.map((datas, i) => {
                JSON.parse(datas.productTitle).map(
                  (info, i) =>
                    (data.productSpecification[i] = {
                      productTitle: info,
                      productAnswer: "",
                    })
                );
                return {
                  productTitle: datas.productTitle,
                  categoryId: datas.categoryId,
                };
              })
            );
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [render]);

  function setTags(e) {
    setData({ ...data, tags: e });
  }

  function handleChange(e) {
    if (e.label) {
      setData({
        ...data,
        categoryName: e.label,
        categoryId: e.id,
        tax: e.tax,
        age: e.age,
      });
      setRender(!render);
    } else setData({ ...data, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!data.moreInfo.length) return;
    if (!data.tags.length) return;
    if (!data.productSpecification.length) return;

    const final = {
      ...data,
      productSpecification: JSON.stringify(data.productSpecification),
    };

    const formdata = new FormData();
    Object.entries(final).map((data) => {
      formdata.append(data[0], data[1]);
    });

    api
      .post("/admin/product/addProduct", formdata)
      .then((res) => {
        notify(res.data.data, "success");
        // setprodDescription("");
        // setData({ productDescription: [] });
        // setData({tags:[]})
        setSubmitRender(!submitRender);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }

  return (
    <div>
      <div className="notification-popup">
        <NotificationAlert ref={notiRef} />
      </div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Products</h1>
              </div>
            </div>

            {/* Add Product */}
            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title float-left">Add Product</h2>
                </header>

                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">
                            Category Name <FieldRequirementMarker type="*" />
                          </Label>
                          <Select
                            required
                            name="cateName"
                            className="select2"
                            options={catelist}
                            styles={customStyles}
                            onChange={handleChange}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleEmail7">
                            Product Name <FieldRequirementMarker type="*" />
                          </Label>
                          <Input
                            required
                            type="text"
                            name="productName"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                          />
                        </FormGroup>
                        {/* <FormGroup>
                          <Label htmlFor="exampleEmail7">Age : </Label>
                          <span style={{color:"red"}}> Add Max Age</span>
                          <Input
                            type="text"
                            name="age"
                            id="exampleEmail7"
                            placeholder=""
                            onChange={handleChange}
                          />
                        </FormGroup> */}
                        <FormGroup>
                          <Label htmlFor="exampleSelect3">
                            Tax Name <FieldRequirementMarker type="*" />
                          </Label>
                          <Select
                            required
                            name="taxName"
                            className="select2"
                            options={taxlist}
                            styles={customStyles}
                            onChange={handleChanged}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Tags <FieldRequirementMarker type="*" />
                            <span
                              style={{ color: "#f8c113", fontWeight: "bold" }}
                            >
                              (Keywords used for searching products in website)
                            </span>
                          </Label>
                          <div className="form-group">
                            <div className="controls">
                              <TagsInput
                                value={data.tags}
                                name="tags"
                                onChange={setTags}
                                onlyUnique={true}
                                inputProps={{
                                  placeholder: "Type and press Enter",
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="productImage">
                            Product Image
                            <FieldRequirementMarker type="*" />{" "}
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Dimension: 480px X 480px | Max size: 3MB)
                            </span>
                          </Label>
                          <br />

                          <input
                            required
                            id="productImage"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              const file = event.target.files[0];
                              if (file) setSelectedImage(file);
                            }}
                          />

                          <br />
                          {/* Cropper */}
                          <div
                            className="row d-flex my-3"
                            style={{ rowGap: "15px" }}
                          >
                            {/* Cropper Tool */}
                            <div
                              className="col-12 col-lg-6"
                              style={{ lineHeight: "0" }}
                            >
                              <Cropper
                                srcImageFile={selectedImage}
                                aspectRatio={"480/480"}
                                getCroppedFile={(croppedFile) => {
                                  setData({ ...data, image: croppedFile });
                                }}
                                getCroppedUrl={(CroppedUrl) => {
                                  setCroppedView(CroppedUrl);
                                }}
                              />
                            </div>

                            {/* Cropper Output */}
                            <div className="col-12 col-lg-6 d-flex align-items-center">
                              {croppedView && (
                                <img
                                  src={croppedView}
                                  style={{
                                    display: "block",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Product Description
                            <FieldRequirementMarker type="*" />
                          </Label>
                          <Input
                            required
                            type="textarea"
                            onChange={handleChange}
                            name="productDescription"
                            id="exampleText"
                          />
                          {/* <ReactQuill
                            modules={modules}
                            theme="snow"
                            value={data.productDescription}
                            onChange={(value) => {
                              //just replica for handleChange Function
                              let event = {
                                target: {
                                  name: "productDescription",
                                  value: value,
                                },
                              };
                              handleChanges(event);
                            }}
                          /> */}
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="exampleText">
                            Additional Information
                            <FieldRequirementMarker type="*" />
                          </Label>
                          {/* <Input
                            type="textarea"
                            onChange={handleChange}
                            name="moreInfo"
                          /> */}
                          <ReactQuill
                            modules={modules}
                            theme="snow"
                            value={data.moreInfo}
                            onChange={(value) => {
                              //just replica for handleChange Function
                              let event = {
                                target: {
                                  name: "moreInfo",
                                  value: value,
                                },
                              };
                              handleChanges(event);
                            }}
                          />
                        </FormGroup>

                        {/* <FormGroup>
                          <Label htmlFor="exampleText">
                            Product Formula
                          </Label>
                          <Select
                            name="productFormula"
                            className="select2"
                            options={productFormula}
                            styles={customStyles}
                            onChange={handleChanges}
                          />
                        </FormGroup> */}
                        {/* <Label htmlFor="exampleText">
                          Is Customize?
                          </Label>
                        <FormGroup check>
          <Label check>
            <Input type="radio" name="customize" value="yes" style={{marginTop:"-10px",marginBottom:"10px"}} onChange={handle}/> Yes
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="customize" value="no" style={{marginTop:"-10px"}} onChange={handle} /> No
          </Label>
        </FormGroup>
       */}

                        {/*---------------------- TECH SPEC--------------------------------------------  */}

                        <header className="panel_header">
                          <h2
                            className="title float-left"
                            style={{ paddingLeft: "0px" }}
                          >
                            Add Technical specifications{" "}
                            <FieldRequirementMarker type="*" />
                          </h2>
                        </header>
                        {!data.productSpecification.length && (
                          <p className="text-danger ">
                            Add Specifications, by picking category (but first
                            set the specification Category)
                          </p>
                        )}

                        <div className="row">
                          {valueList.map((info, index) =>
                            JSON.parse(info.productTitle).map((datas, i) => (
                              <div className="col-lg-6">
                                <FormGroup>
                                  <Label
                                    htmlFor="exampleText"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {datas} <FieldRequirementMarker type="*" />
                                  </Label>
                                  <Input
                                    required
                                    type="text"
                                    name="specificationValue"
                                    onChange={(e) => {
                                      data.productSpecification[
                                        i
                                      ].productAnswer = e.target.value;
                                      // setRender(!renderScreen);
                                    }}
                                  />
                                </FormGroup>
                              </div>
                            ))
                          )}
                        </div>

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#000",
                              color: "#fff",
                            }}
                          >
                            Submit
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div></div>

            <ProductList render={submitRender} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddProduct;
